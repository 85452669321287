import {StepDescription} from "./StepDescription";
import {useEffect} from "react";

export const StepDescriptionContainer = ({step}) => {
    useEffect(()=>{
        const descriptions = document.getElementsByClassName('step__description')
        for (var steps = 0; steps < descriptions.length; steps++) {
            if (steps !== step) {
                descriptions[steps].style.display = 'none'
            } else {
                descriptions[steps].style.display = 'flex'
            }
        }
    },[step])
    return (
        <div className="step__descriptions">
            <StepDescription title={'Marcado'} description={'El marcado se realiza replanteo del pozo para la excavación, en conformidad del propietario y acorde a los planos. Se marca el terreno con estacas, se toma el nivel del piso terminado y se encuadra con tanza para la futura excavación.'} cta_text={'Pedir presupuesto'} cta_action={'/contacto'}/>
            <StepDescription title={'Excavación'} description={'La misma puede ser a mano o con máquina, luego se efectúa el perfilado para las vigas estructurales en sentido longitudinal y transversal.'} cta_text={'Pedir presupuesto'} cta_action={'/contacto'}/>
            <StepDescription title={'Mallado'} description={'En piso y paredes, se confeccionan vigas longitudinales cada 1,50mts y vigas transversales cada 2,00mts con hierro de 8mm y estribos de 6mm cada 20cm. El emparrillado se realiza todo con hierro de 8mm cada 15cm en la totalidad de la piscina.'} cta_text={'Pedir presupuesto'} cta_action={'/contacto'}/>
            <StepDescription title={'Paredes'} description={'Se carga todo con hormigón gunitado H30, un espesor de 15cm en piso y paredes. En vereda y solarium atérmico el hormigonado es de 10cm.'} cta_text={'Pedir presupuesto'} cta_action={'/contacto'}/>
            <StepDescription title={'Revoque'} description={'El revoque es de 2cm de espesor aproximadamente realizado con material hidrófugo (ceresita) para convertirlo en impermeable.'} cta_text={'Pedir presupuesto'} cta_action={'/contacto'}/>
            <StepDescription title={'Revestimiento'} description={'Luego es fratachado, dependiendo de la terminación (pintura o revestimiento) se aplica revoque fino o grueso.'} cta_text={'Pedir presupuesto'} cta_action={'/contacto'}/>
        </div>
    )
}